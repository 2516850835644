.outcome-section-wrapper{
    background-color: #DFF5EB;
    border-radius: 4px;
    min-height: 350px;
    padding: 2%;
    max-height: 500px;
    overflow-y: auto;
    @include customScrollbars();

    .default-outcomes-wrapper{
        border: 2px solid #11D17E;
        border-radius: 3px;
        background-color: #FFFFFF;
        min-height: 100px;
        padding: 1% 0;
        margin-bottom: 2%;

        .rows{
            display: flex;
            min-height: inherit;
            .outcome-list{
                // width: 50%;
                padding: 1% 2%;
                // display: flex;
                // flex-direction: column;
                // gap: 1.2rem;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 0.5em;
                width: 100%;
                background: linear-gradient(#B4B4B4,#B4B4B4) center/2px 100% no-repeat;
    

                .form-group{
                    margin-bottom: 0;
                    label{
                        padding-top: 0;
                    }
                }

                .outcome-title-row{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 1rem;

                    .outcome-config{
                        font-size: 12px;
                        // .select
                        // .react-select__option {}

                        .select__control{
                            min-height: 22px;
                            cursor: pointer;
                        }

                        .select__value-container{
                            height: 22px;
                            min-width: 55px;
                            max-width: 60px;
                        }
                        .select__indicator-separator{
                            display: none;
                        }
                        .select__indicators{
                            height: 22px;
                        }
                        .select__input{
                            // display: none !important;
                            width: 0px;
                            padding: 0;
                        }
                        .select__dropdown-indicator {
                            padding: 0 2px;
                        }
                        .select__clear-indicator{
                            width: 15px;
                            padding: 0;
                        }
                        // .select__control{
                        //     max-height: 10px;
                        //     min-width: 100px;
                        // }
                    }
                }
            }
        }
        input{
            box-shadow: none !important;
        }

        .divider {
            width: 2px;
            background: #B4B4B4;
        }
        
        .radio-button-row {
            display: flex;
            // justify-content: space-between;
            align-items: center;
            gap: 20px; /* Adjust the spacing between buttons */
            // padding: 10px;
          }
          
          .radio-button {
            display: flex;
            align-items: center;
            cursor: pointer;
            // font-family: Arial, sans-serif;
            font-size: 12px;
            padding-top: 0;
          }
          
          .radio-button input[type="radio"] {
            display: none; /* Hide the default radio button */
          }
          
          .custom-radio {
            width: 20px;
            height: 20px;
            border: 2px solid #11D17E;
            border-radius: 50%;
            margin-right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: background-color 0.3s ease, border-color 0.3s ease;
          }
          
          .radio-button input[type="radio"]:checked + .custom-radio {
            background-color: #11D17E;
            border-color: #11D17E;
          }
          
          .custom-radio::after {
            content: '';
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: white;
            display: none;
          }
          
          .radio-button input[type="radio"]:checked + .custom-radio::after {
            display: block;
          }          
    }
    .heading{
        color: #454647;
        font-weight: 600;
        font-size: 14px !important;
        text-align: left;
    }

    .custom-outcome-wrapper{
        display: grid;
        // grid-template-columns: repeat(auto-fill,minmax(300px,1fr));
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 2rem;

        .custom-outcome{
            // width: 285px;
            min-height: 125px;
            border-radius: 3px;
            background-color: #FFFFFF;
            border: 2px solid #11D17E;
            padding: 2%;

            .outcome-d{
                background-color: #efefef;
                min-height: 60px;
            }

            .outcome-t{
                margin: 0 0 3% 0;
                border-bottom: 1px solid #B4B4B4 !important;
            }

            .checkbox-row{
                
                .checkbox-title{
                    font-weight: 500;
                    font-size: 13px;
                    color: #888888;
                    display: flex;
                    gap: 2%;
                    margin-bottom: 0;
                    margin-top: 3%;

                    &:last-child{
                        margin-top: 1%;
                        padding-top: 1%;
                    }
                }
            }

            .delete-outcome{
                display: flex;
                justify-content: flex-end;
                img{
                    width: 15px;
                }
                &:hover{
                    cursor: pointer;
                }
            }

            .suggestion-list{
                padding: 0 2px;
                font-size: 14px;
                text-align: left;
                border: 1px solid #efefef;
                cursor: pointer;
            }
        }
    }

    .disable-outcome-user-notice{
        font-weight: 800 !important;
        font-size: 12px;
        color: #555555;
        background-color: var(--color-yellow);
        padding: 0.8rem;
        text-align: left;
        display: flex;
        gap: 0.5rem;
        img{
            width: 26px;
        }
    }

    .add-outcome{
        max-width: 120px;
        border: 2px solid #17BCBC;
        border-radius: 3px;
        background-color: #FFFFFF;
        color: #17BCBC;
        font-weight: 600;
        font-size: 14px;
        margin-top: 2%;
        padding: 1%;
        word-wrap: break-word;
        &:hover{
            cursor: pointer;
        }
    }

    .red-delete-img{
        width: 15px;
        &:hover{
          cursor: pointer;
        }
        filter: invert(50%) sepia(56%) saturate(2649%) hue-rotate(325deg) brightness(91%) contrast(91%);
      }
}